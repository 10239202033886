import { graphql } from "gatsby"
import React from "react"

import BrandsCarouselComponent from "./BrandsCarousel"

export const fragment = graphql`
  fragment BrandsCarouselFragment on WpPage_Flexlayouts_FlexibleLayouts_BrandsCarousel {
    brandLogos {
      logo {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              quality: 75
              width: 125
              placeholder: NONE
              transformOptions: { grayscale: true }
            )
          }
        }
      }
      link {
        title
        url
      }
    }
    config {
      backgroundColour
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFBrandsCarousel = ({
  brandLogos,
  config,
}) => (
  <BrandsCarouselComponent
    brandLogos={brandLogos}
    config={config}
  />
)
