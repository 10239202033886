// extracted by mini-css-extract-plugin
export var bgGrey100 = "BrandsCarousel-module--bg-grey-100--72f49";
export var bgGrey150 = "BrandsCarousel-module--bg-grey-150--a5f42";
export var bgGrey200 = "BrandsCarousel-module--bg-grey-200--a5021";
export var bgGrey300 = "BrandsCarousel-module--bg-grey-300--09ee9";
export var bgGrey400 = "BrandsCarousel-module--bg-grey-400--b63bb";
export var bgGrey500 = "BrandsCarousel-module--bg-grey-500--f7569";
export var bgGrey600 = "BrandsCarousel-module--bg-grey-600--c3aa6";
export var bgGrey700 = "BrandsCarousel-module--bg-grey-700--02416";
export var bgGrey800 = "BrandsCarousel-module--bg-grey-800--2d321";
export var bgGrey900 = "BrandsCarousel-module--bg-grey-900--bb93e";
export var logoImg = "BrandsCarousel-module--logoImg--7f04a";
export var slideItem = "BrandsCarousel-module--slideItem--19cea";
export var textGrey100 = "BrandsCarousel-module--text-grey-100--70f95";
export var textGrey150 = "BrandsCarousel-module--text-grey-150--e395d";
export var textGrey200 = "BrandsCarousel-module--text-grey-200--28bf3";
export var textGrey300 = "BrandsCarousel-module--text-grey-300--f0958";
export var textGrey400 = "BrandsCarousel-module--text-grey-400--20b88";
export var textGrey500 = "BrandsCarousel-module--text-grey-500--bfff5";
export var textGrey600 = "BrandsCarousel-module--text-grey-600--f4664";
export var textGrey700 = "BrandsCarousel-module--text-grey-700--93fec";
export var textGrey800 = "BrandsCarousel-module--text-grey-800--132c7";
export var textGrey900 = "BrandsCarousel-module--text-grey-900--e7161";