import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { v4 as uuidv4 } from "uuid"

import { createLocalLink } from "../../../utils"
import { Section } from "../../UI/Common"

import { slideItem, logoImg } from "./BrandsCarousel.module.scss"


const BrandsCarousel = ({ brandLogos, config }) => {
  const { backgroundColour, padding } = config || {}

  const slider = useRef(null)

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
  }

  
  return (
    <Section padding={padding} className={backgroundColour}>
      <Slider ref={slider} {...settings}>
        {brandLogos.map(item => {
          return (
            <div className={slideItem} key={uuidv4()}>
              {item.link ? (
                <a
                  href={createLocalLink(item.link.url)}
                  target="_blank"
                  rel="noreferrer">
                  <GatsbyImage
                    className={logoImg}
                    image={getImage(item.logo.localFile)}
                    alt={item.logo.altText}
                  />
                </a>
              ) : (
                <GatsbyImage
                  className={logoImg}
                  image={getImage(item.logo.localFile)}
                  alt={item.logo.altText}
                />
              )}
            </div>
          )
        })}
      </Slider>
    </Section>
  )
}

export default BrandsCarousel
